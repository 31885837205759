<template>
  <div class="page">
    <div class="title">{{pageData.title}}</div>
    <div class="content" v-html="pageData.content">{{pageData.content}}</div>
  </div>
</template>

<script>
  export default {
    name: "single-page",
    data() {
      return {
        pageData: ''
      }
    },
    mounted() {
      console.log(this.$route);
      this.api.singlePage(this.$route.params.code).then(res => {
        this.pageData = res.data
      })
    }
  }
</script>

<style scoped>
.page{
  max-width: 1000px;
  padding: 100px;
}
.title {
  font-weight: bold;
  font-size: 24px;
  color: #fff;
}
.content{
  color: rgb(121, 122, 142);
  padding-bottom: 100px;
}
.content a {
    color: #ff3465;
  }
</style>
